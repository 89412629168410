<template>
	<div class="container">
		<el-form :model="ruleForm" ref="ruleForm" label-width="150px">
			<el-card class="box-card">
				<div class="content">
					<el-form-item
						label="accessKeyId："
						:inline-message="true"
						prop="accessKeyId"
						:rules="[{required:true,message:'accessKeyId不能为空',trigger: 'blur'}]"
					>
						<el-input v-model="ruleForm.accessKeyId" style="width: 300px;"></el-input>
					</el-form-item>

					<el-form-item
						label="accessKeySecret："
						:inline-message="true"
						prop="accessKeySecret"
						:rules="[{required:true,message:'accessKeySecret不能为空',trigger: 'blur'}]"
					>
						<el-input v-model="ruleForm.accessKeySecret" style="width: 300px;" maxlength="20"></el-input>
					</el-form-item>

					<el-form-item
						label="短信签名："
						:inline-message="true"
						prop="signature"
						:rules="[{required:true,message:'短信签名不能为空',trigger: 'blur'}]"
					>
						<el-input v-model="ruleForm.signature" style="width: 300px;" maxlength="20"></el-input>
					</el-form-item>

					<el-form-item
						label="国际短信签名："
						:inline-message="true"
						prop="internalSignature"
						:rules="[{required:true,message:'国际短信签名不能为空',trigger: 'blur'}]"
					>
						<el-input v-model="ruleForm.internalSignature" style="width: 300px;" maxlength="20"></el-input>
					</el-form-item>
				</div>
			</el-card>
		</el-form>
		<div style="height:80px;"></div>
		<div class="box-card footer">
			<el-button style="width:240px;" @click="cancelSupiler()">取消</el-button>
			<el-button style="width:240px;" type="primary" @click="saveSupiler()" :loading="loading">保存</el-button>
		</div>
	</div>
</template>

<script>
import { getSmsSetting, saveSmsSetting } from "@/api/setting";
export default {
	data () {
		return {
			ruleForm: {
				accessKeyId: '',    //accessKeyId
				accessKeySecret: '',       //accessKeySecret
				internalSignature: '', //国籍短信签名
				signature: '',  //短信签名
			},
			loading: false,  //保存接口防止多次点击
		};
	},
	computed: {

	},
	created () {
		this.getSmsSetting();
	},

	methods: {
		//获取详细数据
		async getSmsSetting () {
			let res = await getSmsSetting();
			this.ruleForm = {
				accessKeyId: res.data.accessKeyId,    //accessKeyId
				accessKeySecret: res.data.accessKeySecret,       //accessKeySecret
				internalSignature: res.data.internalSignature, //国籍短信签名
				signature: res.data.signature,  //短信签名
			}

		},
		// 保存
		saveSupiler () {
			this.$refs.ruleForm.validate((valid) => {
				if (valid) {
					this.sureSave();
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		//保存
		async sureSave () {
			this.loading = true
			let res = await saveSmsSetting(this.ruleForm);

			if (res.success) {
				this.$message({
					type: 'success',
					message: '保存成功!'
				});
			}
			this.loading = false;
		},
		//取消保存
		cancelSupiler () {
			this.getSmsSetting();
		},
	},
};
</script>

<style lang="less" scoped>
.container {
	// padding: 10px;
	width: 100%;
	overflow: hidden;

	/deep/.el-textarea {
		width: 500px;
	}
}

.footer {
	position: fixed;
	width: 100%;
	background: #fff;
	bottom: 0;
	text-align: center;
	padding: 20px 0;
	z-index: 100;
	box-shadow: 13px 1px 6px #999;
}
</style>
